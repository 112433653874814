import React, {useEffect, useState} from "react";
import Stepper from "react-stepper-horizontal";

import DualListBox from "react-dual-listbox";
import VinImportService from "../../services/VinImportService";
import CFAdminVinDestinationValues from "./CFAdminVinDestinationValues";
import {MultiAttributeSelectOption} from "./index";

type CFAdminReconcileProps = {
    vinFrag: string;
    attrId: string;
    attrName: string;
    selectedAttrName: string;
    sourceFieldValuesStr: string;
    destFieldValuesStr: string;
    handleAttributeOptions: any;
    vinMappedAttributes: any;
    attributesOptions:any;
    toggleModal: any;
    successMessage: any;
    errorMessage: any;
};

const stepMapping : any = {
    0: [1],
    1: [0, 2],
    2: [1, 3],
    3: [2],
};

function CFAdminReconcileAttribute ({
                                        vinFrag,
                                        attrId,
                                        attrName,
                                        selectedAttrName,
                                        attributesOptions,
                                        handleAttributeOptions,
                                        vinMappedAttributes,
                                        toggleModal,
                                        successMessage,
                                        errorMessage
                                    }: Readonly<CFAdminReconcileProps>) {

    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        {title: 'Choose Source Columns', onClick: () => setActiveStep(0)},
        {title: 'Choose Destination Attributes', onClick: () => setActiveStep(1)},
        {title: 'Choose Destination Field Values', onClick: () => setActiveStep(2)},
        {title: 'Save and Apply Rule', onClick: () => setActiveStep(3)},
    ];
    const [nextStep, setNextStep] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState<any[]>([selectedAttrName]);
    const [selectedDestOptions, setSelectedDestOptions] = useState<any[]>([attrId]);
    const [attributeOptionsMap, setAttributeOptionsMap] = useState<Map<string, MultiAttributeSelectOption[]>>(new Map());
    const [isForBrandRule, setIsForBrandRule] = useState(false);
    const [isSeriesSelected, setIsSeriesSelected] = useState(true);
    const [newDescValue, setNewDescValue] = useState("");
    const [selectValue, setSelectValue] = useState("");
    const [selectLabel, setSelectLabel] = useState("");

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsForBrandRule(event.target.checked);
    };

    const handleSelected = async (selectedOptions: any) => {
        handleAttributeOptions(selectedOptions);
        setSelectedOptions(selectedOptions);
    };

    const handleDestSelected = async (selectedOptions: any) => {
        handleAttributeOptions(selectedOptions);
        setSelectedDestOptions(selectedOptions);
    };

    useEffect(() => {
        console.log("::::selectedAttrName::", selectedAttrName);
        setSelectedOptions([selectedAttrName]);
    }, [selectedAttrName]);

    useEffect(() => {
        const nextSteps = stepMapping[activeStep];
        if (nextSteps.includes(nextStep)) {
            handleStepperNext(nextStep);
        } else {
            console.error("Unrecognized step");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, nextStep]);

    const handleNextClick = () => {
        if(activeStep === 1){
            getVehicleAttributeValues();
        }
        setNextStep(activeStep + 1);
    };

    const handleAttributeSelected = async (id: any, value: any, text: any) => {
        console.log("id::", id, "::value::", value);
        if(id === "NEW"){
            setNewDescValue(value);
            setIsSeriesSelected(false);
        }else if(id === "SELECT"){
            setSelectValue(value);
            setSelectLabel(text);
            setIsSeriesSelected(true);
        }else if(id === "RADIO") {
            setIsSeriesSelected(value);
        }
    };

    const getVehicleAttributeValues = async () => {
        const request = {
            "attributeIds": [attrId]
        };
        VinImportService.getVehicleAttributeValues(request)
            .then(response => {
                console.log("length::", response.data.attributeValues.length);
                let attributeValuesMap = new Map<string, MultiAttributeSelectOption[]>();
                if(response.data.attributeValues.length  > 0) {
                    // Sort the attributeValues array by attributeValueDescription in ascending order
                    response.data.attributeValues.sort((a: any, b: any) => {
                        return a.attributeValueDescription.localeCompare(b.attributeValueDescription);
                    });
                    response.data.attributeValues.forEach((element: any) => {
                        const data: MultiAttributeSelectOption = {
                            label: element.attributeValueDescription,
                            value: element.attributeValueId
                        };
                        if (!attributeValuesMap.has(element.attributeId+"-"+element.attributeDescription)) {
                            attributeValuesMap.set(element.attributeId+"-"+element.attributeDescription, []);
                        }
                        attributeValuesMap.get(element.attributeId+"-"+ element.attributeDescription)?.push(data);
                    });
                }
                setAttributeOptionsMap(attributeValuesMap);
            })
            .catch(e => {
                errorMessage("Failed to get getVehicleAttributeValues Data")
                console.log(e);
            });
    };

    const handlePreviousClick = () => {
        setNextStep(activeStep - 1);
    };

    const handleSaveClick = () => {
        let sourceColumn = selectedAttrName.split('=')[0]? selectedAttrName.split('=')[0].trim(): '';
        let sourceValue = selectedAttrName.split('=')[1]? selectedAttrName.split('=')[1].trim(): '';
        let sourceAttributeValues: any[] = [
            {
                sourceColumn: sourceColumn.substring(1).charAt(0).toLowerCase()+ sourceColumn.slice(2),
                sourceValue: sourceValue.substring(1, sourceValue.length - 1),
            }
        ];
        let destinationAttributeValues: any[] = [
            {
                "attributeId": attrId,
                "attributeValueId":  isSeriesSelected?+selectValue:null,
                "attributeValueDescription": !isSeriesSelected?newDescValue:" "
            }
        ];
        const request = {
            "sourceAttributeValues" : sourceAttributeValues,
            "destinationAttributeValues": destinationAttributeValues,
            "fordFlag": isForBrandRule
        }
        console.log("request::", request);
        VinImportService.saveRule(request)
            .then(response => {
                console.log("response::", response.data);
                if(response.data) {
                    successMessage("Rule saved successfully-", response.data.description);
                    toggleModal();
                }
            })
            .catch(e => {
                errorMessage("Failed to get handleSaveClick")
                console.log(e);
            });
    };

    const handleStepperNext = (nextStep: number) => {
        setActiveStep(nextStep);
    };

    return (
        <>
            <div className="stepper">
                <Stepper steps={steps} activeStep={activeStep}/>
                <div className="active-step">
                    <>
                        {activeStep === 0 && (
                            <>
                                <div className="ext2">
                                    Below is a list of imported values for VIN fragment <b>{vinFrag}</b> and their
                                    associated source columns.
                                </div>
                                <div className="ext2">
                                    Please choose the source columns for mapping the <b> {attrName}</b> attribute.
                                </div>
                                <div className="ext4 scroll">
                                    <DualListBox
                                        options={attributesOptions ? attributesOptions : []}
                                        selected={selectedOptions}
                                        onChange={(selected: any) => {
                                            handleSelected(selected);
                                        }}
                                        data-testid='attributes-test'
                                    />
                                </div>
                            </>
                        )}
                        {activeStep === 1 && (
                            <>
                                <div className="ext2">
                                    Selected below is the unmapped <b>{attrName}</b> attribute of the VIN
                                    fragment <b>{vinFrag}</b>
                                </div>
                                <div className="ext2">
                                    Please click continue or select additional destination attributes.
                                </div>
                                <div className="ext2">
                                    Partial Mapping Rule: ---- <b> If {selectedAttrName.slice(1)} </b>
                                </div>
                                <div className="ext4 scroll">
                                    <DualListBox
                                        options={vinMappedAttributes ? vinMappedAttributes : []}
                                        selected={selectedDestOptions}
                                        onChange={(selected: any) => {
                                            handleDestSelected(selected);
                                        }}
                                        data-testid='attributes-test'
                                    />
                                </div>
                            </>
                        )}
                        {activeStep === 2 && (
                            <>
                                <div className="ext2">
                                    Displayed below are the destination attributes for the VIN fragment <b>{vinFrag}</b>
                                </div>
                                <div className="ext2">
                                    Please choose or enter the destination attribute values for the mapping rule.
                                </div>
                                <div className="ext2">
                                    Partial Mapping Rule: ---- <b> If {selectedAttrName.slice(1)} </b>
                                </div>
                                <div className="ext4 scroll">
                                    {attributeOptionsMap && (
                                        <CFAdminVinDestinationValues
                                            handleAttributeSelected={handleAttributeSelected}
                                            attributeOptionsMap={attributeOptionsMap}/>
                                    )}
                                </div>
                            </>
                        )}
                        {activeStep === 3 && (
                            <>
                                <div className="ext2">
                                    Displayed below is your new mapping rule for the VIN fragment <b>{vinFrag}</b>
                                </div>
                                <div className="ext2">
                                    Click Save and Apply to save the new rule and reapply the rules to the data.
                                </div>
                                <div className="ext2">
                                    New Mapping Rule: ----
                                    <b>
                                        If {selectedAttrName.slice(1)}  Then {attrName} = '{isSeriesSelected ? selectLabel : newDescValue}'
                                    </b>
                                </div>
                                <div className="ext4">
                                    <input
                                        type="checkbox"
                                        name="forBrandRule"
                                        checked={isForBrandRule}
                                        onChange={handleCheckboxChange}
                                    /> Ford Brand Rule
                                </div>
                            </>
                        )}
                    </>
                    <div className="modal-footer button-frame">
                        <div className="right-align">
                            {(activeStep !== 0) && (
                                <button data-testid="step-popup-prev"
                                        className="fvp-button button-right-align" type="button"
                                        onClick={handlePreviousClick}>
                                    <i className="fvp-left-chevron"/> Previous
                                </button>
                            )}
                            {activeStep !== steps.length - 1 && (
                                <button data-testid="step-popup-next"
                                        className="fvp-button button-right-align" type="button"
                                        onClick={() => handleNextClick()}>
                                    Next <i className="fvp-right-chevron"/>
                                </button>
                            )}
                            {(activeStep === 3) && (
                                <button data-testid="step-popup-save"
                                        className="fvp-button button-right-align" type="button"
                                        onClick={handleSaveClick}>
                                    Save Rule
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CFAdminReconcileAttribute;