import { authHeader } from "../util/auth-header";
import http from "../util/http-common";

export class VinImportService {

  static getLastImport() {
    return http.get("/cfadminvinadmin/api/v1/vin-import",{ headers: authHeader()});
  }

  static getImportStatus() {
    return http.get("/cfadminvinadmin/api/v1/vin-import/status",{ headers: authHeader()});
  }

  static initiateVinImport(data: any) {
    return http.post(`/cfadminvinadmin/api/v1/vin-import`,data ,{ headers: authHeader()});
  }

  static getRulesApplyStatus() {
    return http.get("/cfadminvinadmin/api/v1/vin-pattern-apply-rules/status",{ headers: authHeader()});
  }

  static initiateRulesApply(data: any) {
    return http.post(`/cfadminvinadmin/api/v1/vin-pattern-apply-rules`,data ,{ headers: authHeader()});
  }

  static getVinFragments() {
    return http.get("/cfadminvinadmin/api/v1/unreconciled-vin-fragments",{ headers: authHeader()});
  }

  static getVinFragmentAttributes(data: any) {
    return http.post("/cfadminvinadmin/api/v1/unreconciled-vin-fragments/attributes", data, { headers: authHeader()});
  }

  static getVehicleAttributeValues(data: any) {
    return http.post(`/cfadminvehicleconfig/api/v1/attribute-values-query/by-attribute-ids`, data, { headers: authHeader()});
  }

  static saveRule(data: any) {
    return http.post(`/cfadminvinadmin/api/v1/mapping-rules`, data, { headers: authHeader()});
  }

}

export default VinImportService;